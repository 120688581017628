import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Footer from './components/Footer';
// import Home from './components/Home';
// import Header from './components/Header';
// import ProductPage from './components/ProductPage';
// import AboutUs from './components/AboutUs';
// import Pricing from './components/Pricing';
// import Login from './components/Login';
// import Blog from './components/Blog';
// import BlogDetail from './components/BlogDetail';
import ComingSoon from './components/ComingSoon';

function App() {
  return (
    <Router>
      <div className="App bg-black">
        {/* <Header /> */}
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} /> */}
          <Route path="/" element={<ComingSoon />} />
        </Routes>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
